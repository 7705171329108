.modal {
	position: relative;
	.close {
		position: absolute;
		top: 15px;
		right: 20px;
	}
}

#modal-change-password {
	.modal-content {
		width: 45%;
	}
}