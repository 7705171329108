@charset "UTF-8";
/* ==========================================================================
    SASS STYLES
    - components
    trejocode
    style
========================================================================== */
/*
    INFORMACIÓN
	- File:           trejocode.scss
	- Versión:        1.1.4
	- Licence:        MIT
	- Author:         DWIT - TrejoCode
    - Update:         19/10/2018
    - Client:         Elfos Gourmet
*/
/* PROPERTIES
   =============================

	1. = COLORS
	2. = FONT FAMILY
    3. = FONT SIZE
    4. = FONT WEIGHT
	5. = PADDINGS
    6. = BORDERS

*/
/*    COLORS    */
/*    FONT FAMILIES    */
/*    FONT SIZES    */
    /*    FONT WEIGHTS 

        - Based on 'Montserrat'

    */
    /*    PADDINGS, MARGINS

        This properties also applies for 'Margins'

    */
/*    BORDER    */
/* RESET
   =============================

   - These properties able the cross compatibility for browsers and removes default properties from browsers.

*/
* {
  padding: 0;
  margin: 0;
  transition: all 400ms ease;
}

::selection {
  background: #1D846F;
  color: #FFFFFF;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  background-color: #FFFFFF;
}

button, input, select, textarea, option {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
}

table {
  border-spacing: 0;
}

div, header, footer, section, article, aside, nav, form, table {
  width: 100%;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

h1, h2, h3, h4, h5, h6 {
  color: #FFFFFF;
}

h1 {
  font-size: 1.8em;
  font-weight: 700;
}

h2 {
  font-size: 1.6em;
  font-weight: 700;
}

h3 {
  font-size: 1.4em;
  font-weight: 700;
}

h4 {
  font-size: 1.2em;
  font-weight: 500;
}

h5 {
  font-size: 0.93em;
  font-weight: 500;
}

h6 {
  font-size: 1.4em;
  font-weight: 400;
}

p {
  font-size: 0.93em;
  color: #9C9C9C;
  line-height: 24px;
}

/* CLASES
   =============================

	1. = FLEX LAYOUT
	2. = ALIGNMENT
    3. = WHITE SPACES
    4. = FORMS
    5. = BUTTONS
    6. = DECORATORS & COLORS

*/
/*    FLEX LAYOUT

    - This stylesheet is based on flexbox.
    - W3 CSS Flexible Box Layout Module:
       https://www.w3.org/TR/css-flexbox-1
    
*/
.flex {
  display: flex;
}

.flex div, .flex img, .flex nav, .flex form {
  display: flex;
}

.container {
  width: 90%;
}

.responsive-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row;
}

.row-responsive {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.auto {
  width: auto;
}

.grid {
  display: grid !important;
}

.gutters {
  margin: 0 auto;
}

.gutters > div {
  margin: 12px;
}

.gutters > div:first-of-type {
  margin-left: 0;
  color: #272727;
}

.gutters > div:last-of-type {
  margin-right: 0;
}

.wrap {
  flex-wrap: wrap;
}

/*    ALIGNMENT    */
.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-arround {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

/*    TEXT ALIGNMENT    */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-right {
  text-align: right;
}

/*    WHITE SPACES    */
.white-space-8 {
  height: 8px;
}

.white-space-16 {
  height: 16px;
}

.white-space-24 {
  height: 24px;
}

.white-space-32 {
  height: 32px;
}

.white-space-48 {
  height: 48px;
}

.white-space-64 {
  height: 64px;
}

/*    FORMS     */
label {
  color: #9C9C9C;
}

.label-error {
  color: #E74C3C;
}

.input {
  color: #9C9C9C;
  font-size: 0.8em;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.input-search {
  min-height: 35px;
  border: 2px solid #E9EBED;
  border-radius: 45px 0 0 45px;
  padding-left: 16px;
}

.input-search:focus,
.input-text:focus {
  border: 2px solid #1D846F;
}

.input-cta-email {
  height: 50px;
  align-self: flex-start;
  padding: 0 8px;
}

.input-number {
  border: 2px solid #E9EBED;
  padding: 8px 4px;
}

.input-text {
  color: #272727;
  border: 2px solid #E9EBED;
  border-radius: 4px;
  font-weight: 500;
  padding: 8px;
}

.textarea {
  color: #272727;
  border: 2px solid #E9EBED;
  border-radius: 4px;
  font-weight: 500;
  padding: 8px;
  line-height: 18px;
}

.input-select {
  border: 2px solid #E9EBED;
  padding: 8px;
  color: #272727;
}

.hidden {
  display: none !important;
}

/*    BUTTONS     */
.btn {
  width: auto;
  height: auto;
  outline: none;
  cursor: pointer;
  background: none;
  color: #FFFFFF;
  font-size: 0.93em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 16px 24px;
}

.btn:active {
  transform: scale(0.96);
}

.btn:disabled {
  background: #272727;
}

.btn:disabled:hover {
  background: #272727;
}

.btn-search {
  min-width: 20%;
  padding: 0;
  color: #9c9c9c;
  border: 2px solid #E9EBED;
  border-radius: 0 45px 45px 0px;
  border-left: 1px solid #E9EBED;
  transition: all 0.2s;
}

.btn-search:active, .btn-search:hover {
  border: 2px solid #1D846F;
  color: #1D846F;
}

.btn-green {
  background: #d8e5dc;
  color: black;
}

.btn-green:hover {
  background: #bacdc0;
}

.btn-code {
  font-weight: 700;
  padding: 8px 16px;
  color: white;
  cursor: pointer;
}

.btn-aqua {
  background: #1D846F;
}

.btn-aqua:hover {
  background: #4A9C62;
}

.btn-aqua-invert {
  background: white;
  color: black;
  border: 2px solid #d8e5dc;
}

.btn-aqua-invert:hover {
  background: #d8e5dc;
  color: black;
  border: 2px solid #d8e5dc;
}

.btn-brown {
  background: #B58316;
}

.btn-brown:hover {
  background: #906811;
}

.btn-red {
  background-color: #E74C3C;
}

.btn-red:hover {
  background-color: #C0392B;
}

.btn-banner {
  box-shadow: 0 0 16px 4px;
  width: /*25%*/18%;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 1.2em;
  font-family: 'Raleway';
}

.link {
  cursor: pointer;
}

.link:hover {
  mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.9) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
  mask-size: 200%;
  animation: shine 1.5s infinite;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }
  to {
    -webkit-mask-position: -50%;
  }
}
.table-responsive {
  overflow-x: auto;
}

/*    DECORATORS & COLORS    */
/*.bordered {
  border: 2px solid #E9EBED;
}*/

.pacifico {
  font-family: "Pacifico", cursive;
}

.color-white {
  color: #FFFFFF;
}

.color-lightGray {
  color: #E9EBED;
}

.color-darkGray {
  color: #6c6666;
}

.category-selected {
  color: #B58316 !important;
  font-weight: 700;
}

.color-black {
  color: #272727;
}

.color-aqua {
  color: #d8e5dc;
}

.color-green {
  color: #4A9C62;
}

.weight-medium {
  font-weight: 500;
}

.weight-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.text-mini {
  font-size: 0.8em;
}

/*    LARGE DEVICES    */
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}
@media screen and (max-width: 1200px) {
  /*body {
    background: lightcoral;
  }*/
}
/*    NOTEBOOKS, IPADS DEVICES    */
@media screen and (max-width: 992px) {
  /*body {
    background: lightblue;
  }*/

  .break-ipad {
    flex-direction: column;
  }
}
/*    TABLETS DEVICES    */
@media screen and (max-width: 768px) {
  /*body {
    background: white;
  }*/

  .row-responsive {
    flex-direction: column;
  }

  .white-space-64 {
    height: 32px;
  }

  .gutters > div {
    margin: 0;
  }
}
/*    MOBILE DEVICES    */
@media screen and (max-width: 576px) {
  /*body {
    background: dimgray;
  }*/

  .input-search {
    min-height: 38px;
    margin-left: -8px;
    padding-left: 8px;
  }
}
/* ==========================================================================
    HEADER
========================================================================== */
header .header-top {
  padding: 16px 0;
  background-color: #F4F5F6;
}

header .header-top .phone, header .header-top .email {
  font-size: 0.9em;
  max-width: 25%;
  min-width: 20%;
}

header .header-top .lang {
  font-size: 0.9em;
}

header .header-main .left {
  width: 80%;
  padding-right: 130px;
}

header .header-main .left, header .header-main .header-right {
  min-width: 25%;
}

header .header-main .middle {
  /*min-width: 50%;*/
  width: 20%;
}

header .header-main .search .input-search {
  min-width: 80%;
}

header .header-main {
  background-color: #FFFFFF;
  padding: 18px 0;
  border-bottom: 2px solid #E9EBED;
}

header .header-main .logo img {
  height: 120px;
}

/*Cambios*/
header .header-main .right {
  padding-bottom: 20px;
}

header .header-main .menu {
  justify-content: space-between;
}

header .header-main .menu .active {
  border-bottom: 3px solid #d8e5dc;
  padding: 0 6px;
}

header .header-main .menu a {
  font-size: 19px;
}
/*CambiosEnd*/

header .header-main .right .user-cart {
  /*width: 100%;*/
  width: 50%;
  display: flex;
  justify-content: space-around;
}

header .header-main .right .user-cart .icon img {
  height: 32px;
}

header .header-main .right .user-cart .icon {
  padding: 5px;
}

header .header-main .right .user-cart .icon-padding {
	padding: 0px 16px;
}

.menu .icon {
  display: none;
}

#close-menu {
  display: none;
}

@media screen and (max-width: 1200px) {
  header .header-top .phone, header .header-top .email {
    min-width: 25%;
  }

  header .header-main .left, header .header-main .header-right {
    min-width: 30%;
  }

  /*header .header-main .middle {
    min-width: 40%;
  }*/

  /*Cambios*/
  header .header-main .right .user-cart {
    width: 60%;
  }

  header .header-main .left {
    width: 65%;
    padding-right: 85px;
  }
}

@media screen and (max-width: 992px) {
  header .header-top .phone, header .header-top .email {
    min-width: 30%;
  }

  header .header-main .left, header .header-main .middle {
    margin-bottom: 8px;
  }

  header .header-main .right .user-cart {
    justify-content: space-between;
  }

  header .header-main .right .user .icon {
    padding: 0 8px;
  }

  header .header-main .middle {
    min-width: 25%;
  }

  /*Cambios*/
  header .header-main .middle {
    width: 100%;
  }

  header .header-main .middle .logo {
    justify-content: center;
  }

  header .header-main .right .user-cart {
    width: 100% !important;
    justify-content: space-around;
  }

  header .header-main .left {
    width: 70%;
    padding-right: 65px;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 768px) {
  header .header-top .phone, header .header-top .email {
    min-width: 40%;
  }

  header .header-main .right .user-cart .cart {
    justify-content: flex-end;
  }

  /*Cambios*/
  header .header-main .middle {
    width: 100%;
  }

  header .header-main .middle .logo {
    justify-content: center;
  }

  header .header-main .right .user-cart {
    width: 100% !important;
    justify-content: space-around;
  }

  .lm {
    flex-direction: column-reverse;
  }

  header .header-main .menu {
    padding-bottom: 20px;
  }

  header .header-main .left {
    width: 70%;
    padding-right: 0;
    margin-bottom: 4px;
  }

  header .header-main .menu a {
    font-weight: 600;
  }
}

@media screen and (max-width: 576px) {
  header .header-top {
    padding: 8px 0;
  }

  header .header-main {
    padding: 20px 0;
  }

  header .header-top .phone, header .header-top .email {
    min-width: 70%;
    font-size: 0.8em;
  }

  header .header-top .lang .lang-text {
    display: none;
  }

  header .header-top .phone {
    display: none;
  }

  /*Cambios*/
  header .header-main .middle {
    width: 100%;
  }

  header .header-main .middle .logo {
    justify-content: center;
  }

  header .header-main .right .user-cart {
    width: 100% !important;
  }

  header .header-main .right .user-cart .icon img {
    height: 25px;
  }

  header .header-main .right .user-cart .icon-padding {
    padding: 0;
  }

  header .header-main .right .user-cart .info a {
    font-size: 14px;
  }

  .lm {
    flex-direction: column-reverse;
  }

  header .header-main .search .input-search {
    min-height: 30px;
    padding-left: 16px;
  }

  header .header-main .menu {
    padding-bottom: 20px;
  }

  header .header-main .left {
    padding-right: 0;
    margin-bottom: 0;
  }

  header .header-main .menu a {
    font-size: 14px;
    font-weight: 600;
  }

  /* Menú hamburguesa */  
  /*.menu {
    position: absolute;
    top: 50px;
  }

  #nav {
    padding-left: 15px;
    cursor: pointer;
  }

  .responsive #close-menu {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px !important;
    display: block;
    cursor: pointer;
  }

  .none a {
    display: none;
  }

  .menu i {
    font-size: 1.2em;
  }

  .none .icon {
    width: 100%;
    display: block;
  }

  .responsive {
    width: 100%;
    height: 100%;
    top: 0 !important;
    position: fixed !important;
    color: #1D846F;
    z-index: 3;
    left: 0%;
    background-color: rgba(0,0,0,.9);
    flex-direction: column;
    justify-content: center !important;
  }

  .responsive a {
    font-size: 30px !important;
    padding-bottom: 25px;
    color: white;
    font-weight: 500 !important;
  }

  .responsive .active {
    border: none !important;
    padding-bottom: 25px !important;
    color: #1D846F;
  }

  .responsive .icon {
    width: 100%;
    text-align: center;
  }*/
}
/* ==========================================================================
    SLIDER
========================================================================== */
.carousel {
  width: 100%;
  background: #FFFFFF;
}

.slide {
  width: 100%;
  min-height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.flickity-page-dots {
  /*display: none;*/
}

.flickity-prev-next-button {
  border-radius: 0 !important;
}

.flickity-prev-next-button .flickity-button-icon {
  top: -70% !important;
}

.slide-h1 {
  font-size: 3em;
}

.slide-h2 {
  font-size: 2em;
}

.slide-p {
  font-size: 1em;
}

@media screen and (min-width: 1440px) {
  .slide {  
    min-height: 800px;
    background-position: bottom;
  }
}

@media screen and (max-width: 768px) {
  .slide-p {
    font-size: 15px;
    text-align: justify;
  }
}
/* ==========================================================================
    Products
========================================================================== */
.products-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 16px;
}

.product {
  min-height: 340px;
  margin-bottom: 16px;
  cursor: pointer;
  padding: 1rem!important;
}

.product:hover {
  /*border: 2px solid #1D846F;*/
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;  
}

.product .image {
  justify-content: center;
}

.product .image img {
  height: 220px;
}

.product .description {
  /*background-color: #E9EBED;*/
  flex-grow: 1;
  padding: 16px 0 0 0;
}

.product .description .name {
  font-size: 1em;
  font-weight: 900;
  letter-spacing: 0.5px;
  min-height: 48px;
}

.product .description .price {
  font-weight: 900;
  font-size: 1.6em;
}

.product .btn {
  flex-grow: 1;
}

.products-main .products-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 16px;
}

@media screen and (min-width: 1440px) {
  .products-main .products-container {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}

@media screen and (max-width: 1200px) {
  .products-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 16px 8px;
  }

  .banner-content {
    width: 60% !important;
  }

  .modal-body-banner {
    height: 400px !important;
  }

  .banner-b {
    bottom: 40px !important;
  }

  .btn-banner {
    font-size: 1.2em;
  }

  .text-banner {
    /*margin-bottom: 210px;*/
    height: 37vh !important;
  }

  .text-banner h1 {
    font-size: 2em !important;
  }
}
@media screen and (max-width: 992px) {
  .products-container {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}
@media screen and (max-width: 768px) {
  .products-container {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .product {
    min-height: 340px;
    margin-bottom: 16px;
  }
}
/* ==========================================================================
    CTA SINGLE
========================================================================== */
.cta-mail-single {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.cta-mail-single form {
  justify-content: flex-end;
}

.cta-mail-single form .input-cta-email {
  min-width: 50%;
}

@media screen and (max-width: 768px) {
  .cta-mail-single {
    text-align: center;
  }

  .cta-mail-single form {
    justify-content: center;
    margin-bottom: var(--paddingLarge);
  }
  .cta-mail-single form a {
    margin-bottom: 32px;
  }
}
.pagination .page {
  border: 4px solid #FFFFFF;
  transition: all 0.1s;
}

.pagination .page:hover {
  background-color: #1D846F;
  border: 4px solid #1D846F;
  color: #FFFFFF;
}

.pagination .page-link {
  padding: 0 8px;
  font-size: 1.2em;
  cursor: pointer;
}

.pagination .current-page {
  border: 4px solid #1D846F;
}

.popover {
  color: #FFFFFF;
  padding: 16px 0;
  background-color: #B58316;
  position: fixed;
  bottom: 0;
  left: 0;
}

.table-responsive th {
  background-color: #E9EBED;
  padding: 16px 0;
}

.table-responsive th,
.table-responsive td {
  text-align: center;
  color: #272727;
}

.table-row td {
  border: 1px solid #E9EBED;
  padding: 8px 0;
}

.table-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-image img {
  max-height: 100px;
}

.table-total-summary {
  justify-content: flex-end;
}

.table-total-summary table {
  max-width: 40%;
}

.table-total-summary td {
  padding: 16px 0;
}

.table-total {
  font-weight: 700;
}

.total-check {
  color: #1D846F !important;
}

.admin .products-table {
  background-color: #FFFFFF;
}

.admin .products-table .cart-container th {
  background-color: #FFFFFF;
}

.admin .products-table .cart-container .table-image img {
  max-height: 45px;
}

.admin .users-table,
.admin .users-table th,
.admin .clients-table,
.admin .clients-table th {
  background-color: #FFFFFF;
}

.admin .clients-table .table-row td {
  padding: 16px;
  font-size: 0.9em;
}

.admin .users-table .table-row {
  cursor: inherit;
}

@media screen and (max-width: 992px) {
  .table-responsive th,
  .table-responsive td {
    padding: 16px 24px;
  }

  .th-name {
    min-width: 300px;
  }

  .table-total-summary table {
    max-width: 50%;
  }

  .banner-content {
    width: 75% !important;
  }

  .modal-body-banner {
    height: 395px !important;
  }

  .banner-b {
    bottom: 35px !important;
  }

  .btn-banner {
    font-size: 1.2em;
  }

  .text-banner {
    /*margin-bottom: 210px;*/
    height: 35vh !important;
  }

  .text-banner h1 {
    font-size: 1.8em !important;
  }
}

@media screen and (max-width: 768px) {
  .table-total-summary table {
    max-width: 100%;
  }

  .banner-content {
    width: 80% !important;
  }

  .modal-body-banner {
    height: 345px !important;
  }

  .banner-b {
    bottom: 30px !important;
  }

  .btn-banner {
    font-size: 1.2em;
  }

  .text-banner {
    /*margin-bottom: 210px;*/
    height: 30vh !important;
  }

  .text-banner h1 {
    font-size: 1.4em !important;
  } 
}
@media screen and (max-width: 576px) {
  .th-name {
    min-width: 320px;
  }

  .banner-content {
    width: 90% !important;
  }

  .modal-body-banner {
    height: 300px !important;
  }

  .banner-b {
    bottom: 30px !important;
  }

  .btn-banner {
    font-size: 1em;
  }

  .text-banner {
    /*margin-bottom: 210px;*/
    height: 28vh !important;
  }

  .text-banner h1 {
    font-size: 1.2em !important;
  } 
}

.modal {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  width: 80%;
  margin: auto;
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.banner-content {
  width: 60%;
  background: transparent;
  box-shadow: none;
}

.modal-body-banner {
  position: relative;
  height: 490px;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.close-modal {
  color: #E74C3C;
  font-size: 1.5em;
  margin-right: 16px;
}

.modal-header {
  padding: 16px 0;
  background-color: #1D846F;
}

.modal-body-container {
  align-items: center;
}

.modal-body .user-data {
  width: 95%;
  justify-content: center;
}

.btn-container-login .btn {
  flex-grow: 1;
}

#modal-login .modal-content {
  width: 40%;
}

#modal-order .cart-container .container {
  width: 95%;
}

#modal-order .cart-container .table-image img {
  max-height: 40px;
}

#modal-order .modal-content
.btn-container-accept {
  min-width: 60%;
}

#modal-order .modal-content
.btn-container-accept button {
  flex-grow: 1;
}

#modal-order .modal-content
.btn-container-cancel button {
  flex-grow: 1;
}

#modal-order .table-total-summary table {
  max-width: 100%;
}

#modal-order .table-client .table-clientInfo,
#modal-order .table-client .table-clientAddress {
  padding: 16px 8px;
}

#modal-add-user .modal-content {
  width: 50%;
}

#modal-add-user .users-data {
  width: 90%;
}

@media screen and (max-width: 1200px) {
  #modal-add-user .modal-content {
    width: 60%;
  }

  #modal-add-user .users-data {
    width: 96%;
  }
}
@media screen and (max-width: 992px) {
  #modal-login .modal-content {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  #modal-login .modal-content {
    width: 65%;
  }
}
@media screen and (max-width: 768px) {
  #modal-login .modal-content {
    width: 80%;
  }
}
/* ==========================================================================
    FOOTER
========================================================================== */
.footer-main {
  background-color: #0b342c;
  /*background-color: #FFFFFF;*/
}

.footer-main .logo-img img {
  height: 140px;
}

.footer-main .social-icon {
  padding: 0 16px;
}

.footer-main .social-icon i {
  font-size: 1.2em;
}

.footer-main .quick-links {
  align-items: flex-end;
}

.footer-main .quick-links .inner {
  width: 50%;
}

.footer-main .quick-link a {
  font-size: 0.93em;
}

.footer-main .quick-info {
  align-items: flex-end;
}

.footer-main .quick-info .inner {
  width: 60%;
}

/*Cambios
.footer-main .quick-links h4, 
.footer-main .quick-info h4,
.footer-main .quick-links i,
.footer-main .quick-info i,
.footer-main .quick-links span,
.footer-main .quick-info a,
.logo h4,
.logo i {
  color: #1D846F;
}*/



@media screen and (max-width: 992px) {
  .footer-main .quick-links .inner {
    width: 80%;
  }

  .footer-main .quick-info .inner {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .footer-main .social {
    justify-content: center;
    margin-bottom: 24px;
  }

  .footer-main .quick-links, .footer-main .quick-info {
    text-align: center;
    margin: 16px 0;
  }

  .footer-main .quick-link {
    text-align: center;
    width: 100%;
    justify-content: center;
  }

  .footer-main .quick-links .inner {
    width: 100%;
  }

  .footer-main .quick-info .inner {
    width: 100%;
  }
}
/* ==========================================================================
    PROMOTION BANNERS
========================================================================== */
.promotion-banners .row-responsive {
  justify-content: center;
}

.promotion-banners .promotion-banner {
  max-width: 30%;
  min-height: 260px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.promotion-banners .promotion-banner-1 {
  max-width: 40%;
  background-position: bottom;
}

.promotion-banner img {
  max-height: 80px;
  object-fit: contain;
}

.promotion-banners .promotion-banner:hover {
  box-shadow: inset 0 0 0 10px #B58316;
}

/* ==========================================================================
    WHY CHOOSE
========================================================================== */
.why-choose-container {
  justify-content: space-between;
}

.why-choose-reason {
  width: 45%;
  background-color: #FFFFFF;
  padding: 32px 0;
}

.why-choose-reason:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
}

.catalog .why-choose-reason {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  background-color: #4A9C62;
  border-radius: 5px;
}

.why-choose-reason .icon {
  margin: 0 16px;
}

.why-choose-reason .column p {
  width: 90%;
}

@media screen and (max-width: 992px) {
  .why-choose-reason {
    width: 48%;
  }
}
@media screen and (max-width: 768px) {
  .promotion-banners .row-responsive {
    justify-content: center;
  }

  .promotion-banners .promotion-banner {
    max-width: 100%;
    min-height: 260px;
    margin-bottom: 24px;
  }

  .why-choose-reason {
    width: 100%;
    margin-bottom: 32px;
  }

  .why-choose-reason .column p {
    width: 95%;
  }
}
.page-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.products-main .left {
  min-width: 70%;
  order: 0;
}

.products-main .sidebar {
  order: 1;
  margin-left: 32px;
  align-self: flex-start;
  position: sticky;
  top: 0;
}

.product-line .container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.product-line .container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.product-line .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.product-line .container-checkbox:hover input ~ .checkmark {
  background-color: #9C9C9C;
}

.product-line .container-checkbox input:checked ~ .checkmark {
  background-color: #B58316;
}

.product-line .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.product-line .container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.product-line .container-checkbox .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.products-main .sidebar .categories .category {
  padding: 8px 0;
  border-bottom: 1px solid #E9EBED;
}

@media screen and (max-width: 768px) {
  .products-main .left {
    order: 1;
  }

  .products-main .sidebar {
    order: 0;
    position: relative;
    margin-left: 0;
    align-self: flex-start;
  }
}
.product-detail .left {
  min-width: 30%;
  margin-right: 32px;
}

.product-detail .right {
  min-width: 70%;
}

.product-detail .right .image {
  margin-right: 24px;
  width: 50%;
}

.product-detail .right .image img {
  /*height: 200px;*/
  height: 300px;
  object-fit: contain;
}

.product-detail .producto-name {
  text-transform: capitalize;
}

.product-detail .product-name, .product-detail .product-summary {
  max-width: 80%;
}

.product-detail .product-add .input-number {
  margin-right: 24px;
}

.relevant {
  align-self: flex-start;
  top: 0;
  position: sticky;
}

.relevant-name h5{
  width: 90%;
}

.relevat-product {
  border-bottom: 2px solid #E9EBED;
}

.relevat-product .image {
  margin-right: 24px;
  padding: 8px;
}

.relevat-product .image img {
  height: 180px;
  object-fit: contain;
}

.relevat-product .info {
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .product-detail .left {
    min-width: 100%;
    margin-right: 0;
  }

  .product-detail .right {
    min-width: 100%;
  }

  .product-detail .right .image {
    margin-right: 16px;
    justify-content: center;
  }

  .product-detail .product-name, .product-detail .product-price, .product-detail .product-summary {
    max-width: 100%;
    justify-content: center;
  }

  .product-detail .product-add, .product-detail .add-to-cart {
    align-items: center;
    justify-content: center;
  }

  .product-detail .product-add .input-number {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .product-detail .left {
    order: 1;
  }

  .product-detail .right {
    order: 0;
  }
}
@media screen and (max-width: 768px) {
  .product-detail .left {
    min-width: 100%;
    margin-right: 0;
  }

  .product-detail .right .image {
    margin-right: 0;
    justify-content: center;
  }

  .relevant {
    align-self: flex-start;
  }

  .relevat-product {
    border-bottom: 2px solid #E9EBED;
    padding: 16px 0;
  }

  .relevat-product .image {
    justify-content: center;
  }

  .relevat-product .info .relevant-name {
    margin: 8px 0;
  }

  .relevat-product .info, .relevat-product .info .relevant-name, .relevat-product .info .relevant-price {
    justify-content: center;
    align-items: center;
  }
}
.checkout-address {
  min-width: 60%;
}

.checkout-order {
  align-self: flex-start;
  position: sticky;
  top: 0;
}

.checkout-order .column {
  align-items: center;
  padding-top: 64px;
}

.btn-container-pay {
  justify-content: center;
}

.checkout-order .table-responsive {
  width: 80%;
}

@media screen and (max-width: 992px) {
  .checkout-address {
    min-width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .checkout-order .table-responsive {
    width: 90%;
  }

  .checkout-order .column {
    padding-top: 0px;
    padding-bottom: 32px;
  }
}
.checkout-status-approved {
  align-items: center;
  min-height: 400px;
}

svg {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.register .personal-info,
.register .address-info {
  max-width: 48%;
}

@media screen and (max-width: 992px) {
  .register .personal-info,
  .register .address-info {
    max-width: 100%;
  }
}
.not-found-page {
  min-height: 500px;
}

.not-found-page h2 {
  color: #fff;
  font-size: 12em;
  font-weight: bold;
  font-family: Helvetica;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}

.not-found-page p {
  text-decoration: line-through;
}

.awards img {
  margin: 0 8px;
  max-height: 100px;
}

.delivery-step:hover > h4 {
  color: #1D846F;
}

.delivery-step .delivery-img {
  justify-content: center;
}

@media screen and (max-width: 576px) {
  .delivery-step {
    min-width: 35%;
    margin: 16px 0;
  }

  .product-detail .right .image {
    width: 100%;
  }
}

.promotion-price {
  font-size: 12px;
  text-decoration: line-through;
}

.close-banner {
  position: absolute;
  right: 0;
  top: 5;
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
}

.close-banner i {
  color: white;
  font-size: 1.8em;
  cursor: pointer;
}

.info-product {
  position: relative;
  margin-right: 36px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 6px #9E9E9E;
}

.banner-b {
  /*position: absolute;
  bottom: 45px;*/
  height: 100vh;
}

.text-banner {
  /*margin-bottom: 210px;*/
  height: 45vh;
}

.text-banner h1 {
  font-size: 2em;
  font-family: 'Della Respira';
}

.promotion-code {
  width: 80%;
}

.promotion-code .column {
  padding-top: 0 !important;
}

.color-red {
  color: darkred;
  cursor: pointer;
}

.usvideo {
  height: 200px;
  padding: 12px;
  border-radius: 12px;
}

.usvideo iframe {
  border-radius: 2px;
}

/* Widget */
#whatsAppWidget {
	position: fixed;
	right: 20px;
	bottom: 13px;
	float: right;
	margin-bottom: 10px;
	width: 0%;
}

#whatsAppWidget .waArea:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

#whatsAppWidget .waArea {
  float: right;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
  text-align: center;
  line-height: 50px;
  color: white;
  background-color: rgb(77, 194, 71);
}

#whatsAppWidget svg {
  display: inline;
  fill: white;
  margin: auto;
  width: 40px;
}

.squareMessage {
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 7px 0px;
  display: block;
  float: left;
  margin-top: 6px;
  padding: 8px;
  max-width: 90px;
  min-width: 34px;
  font-size: 12px;
  font-weight: 500;
  color: rgb(138, 138, 138);
  background-color: rgb(255, 255, 255);
  box-sizing: content-box;
  line-height: initial;
  clear: both;
  word-break: break-word;
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{overflow:hidden;position:relative;height:100%}.flickity-slider{position:absolute;width:100%;height:100%}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:-webkit-grab;cursor:grab}.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:-webkit-grabbing;cursor:grabbing}.flickity-button{position:absolute;background:hsla(0,0%,100%,.75);border:none;color:#333}.flickity-button:hover{background:#fff;cursor:pointer}.flickity-button:focus{outline:0;box-shadow:0 0 0 5px #19f}.flickity-button:active{opacity:.6}.flickity-button:disabled{opacity:.3;cursor:auto;pointer-events:none}.flickity-button-icon{fill:#333}.flickity-prev-next-button{top:50%;width:44px;height:44px;border-radius:50%;transform:translateY(-50%)}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button .flickity-button-icon{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin:0 8px;background:#333;border-radius:50%;opacity:.25;cursor:pointer}.flickity-page-dots .dot.is-selected{opacity:1}