.navbar {
	width: 100%;
	background-color: #555;
	overflow: auto;
	display: flex;
	justify-content: space-between;

	.active {
		background-color: #2A8875;
		&:hover {
			background-color: #2A8875;
		}
	}

	a {
		cursor: pointer;
		justify-content: center;
		padding: 12px;
		color: white;
		text-decoration: none;
		font-size: 17px;
		width: 100%; /* Four links of equal widths */
		text-align: center;
		&:hover {
			background-color: #000;
		}
	}
}